/* Main.css */
@font-face {
  font-family: "Sansation";
  src: url("fonts/sansation/Sansation_Regular.ttf") format("truetype");
  /* Path to your sansation font file */
}

@font-face {
  font-family: "Poppins";
  src: url("fonts/poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Sansation-Light";
  src: url("fonts/sansation/Sansation_Light.ttf") format("truetype");
  /* Path to your sansation font file */
}

@font-face {
  font-family: "Space Age";
  src: url("fonts/space_age/Space_Age.ttf") format("truetype");
  /* Path to your Space Age font file */
}

@font-face {
  font-family: "SF Mono";
  src: url("fonts/SF_Mono/SFMonoBold.otf")
}

.landing-page {
  /* Replace with your actual background image */
  background: #041b3a url("../src/components/assets/background.jpeg");
  background-size: contain;
  font-family: "Arial", sans-serif;
  color: white;
  text-align: center;
  height: 125vh;
}

td,
th {
  border-bottom: 1px solid;
  padding-left: 40px;
  margin-left: auto;
  margin-right: auto;
  font-size: 30px;
  color: white;
}

.table-internal {
  padding: 30px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
}

.table {
  margin-left: auto;
  padding: 20px;
  margin-right: auto;
  color: white;
  border: 1px solid;
  font-size: 25px;
}

.greybox {
  background: linear-gradient(to right, #BFC3C6, #e8e8e8);
  padding: 10vh 10vw;
  color: black;
  overflow-y: visible;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Subtle box shadow */
  border-radius: 8px;
  /* Slight border radius */
  text-align: center;
  /* Center-align text */
  line-height: 1.6;
}


.info-packet-text {
  font-family: 'Poppins', sans-serif;
  font-size: calc(0.79vw + 0.78vh) !important;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}


.lightbluebox {
  background-color: #00a3e6;
  padding: 10vh;
  color: white;
  width: auto;
  height: 145vh;
  font-family: "Sansation", sans-serif;
  overflow-y: auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Subtle box shadow */
  border-radius: 8px;
  /* Slight border radius */
  text-align: center;
  /* Center-align text */
  line-height: 1.6;
  /* Adjust line height for better readability */
}

.bluebox {
  background-color: #002360;
  padding: 10vh;
  color: black;
  height: calc(30vh - 40px);
  font-family: "Sansation", sans-serif;
  overflow-y: auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Subtle box shadow */
  border-radius: 8px;
  /* Slight border radius */
  text-align: center;
  /* Center-align text */
  line-height: 1.6;
  /* Adjust line height for better readability */
}

.greyboxIcon {
  background-color: #565656;
  padding: 10vh;
  color: black;
  height: calc(30vh - 40px);
  font-family: "Sansation", sans-serif;
  overflow-y: auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Subtle box shadow */
  border-radius: 8px;
  /* Slight border radius */
  text-align: center;
  /* Center-align text */
  line-height: 1.6;
  /* Adjust line height for better readability */
}

.team-container {
  background: transparent;
  /* -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, .2)); */
  /* filter: drop-shadow(0 4px 4px rgba(0, 0, 0, .2)); */
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  margin: 10vh 0;
  padding: 0 10vw;
}

.team-member {
  padding-top: 5px;
  background-color: white;
  -webkit-transition: background-color 1s;
  -moz-transition: background-color 1s;
  -ms-transition: background-color 1s;
  -o-transition: background-color 1s;
  transition: background-color 1s;
  font-family: "Sansation";
  color: black;
  flex-wrap: wrap;
  justify-content: center;
  border-radius: 8px;
}

.team-member:hover {
  box-shadow: inset 0 0 50px #fff,
    /* inner white */
    inset 20px 0 80px #f0f,
    /* inner left magenta short */
    inset -20px 0 80px #0ff,
    /* inner right cyan short */
    inset 20px 0 300px #f0f,
    /* inner left magenta broad */
    inset -20px 0 300px #0ff,
    /* inner right cyan broad */
    0 0 50px #fff,
    /* outer white */
    -10px 0 80px #f0f,
    /* outer left magenta */
    10px 0 80px #0ff;
  /* outer right cyan */

  transform: translateY(-2px);
  opacity: 90%;
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.2));
  transition: all 0.5s ease-in-out;
}

.greybox p {
  margin: 0;
  font-size: 16px;
  /* Adjust font size for better readability */
}

.landing-header {
  display: flex;
  justify-content: space-between;
  /* Aligns items to the start and end of the container */
  padding: 1vw;
  background: rgb(2, 14, 31);
  align-items: center;
}

.landing-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  height: 100%;
}

.header-logo {
  /* Adjust the size of your logo as necessary */
  height: auto;
  /* Example height for your logo */
  width: 10vw;
  align-self: center;
  padding-right: 32vw;
}

.landing-nav ul li {
  height: 4vw;
  margin-left: 4.5vw;
}

.landing-nav ul li a {
  font-family: "Sansation", sans-serif;
  font-size: 1vw;
  color: #ffffff;
  transition: color 0.3s;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 4vw;
}

.landing-nav ul li a:hover {
  color: #67a0d1;
  /* Change to the desired hover color */
}

.top-logos {
  width: 50%;
  padding-left: 25%;
  padding-top: 2vh;
  padding-bottom: 2vh;
}

.top-logo {
  height: auto;
  width: 14vw;
  padding-right: 2vw;
  padding-left: 2vw;
}

.hero {
  padding-top: 2vh;
  padding-bottom: 2vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-origin: content-box;
}

.name-text {
  font-family: "Sansation-Light", sans-serif;
  font-size: 1.8vw;
  /* A3djust the size as needed */
  color: #3871c1;
  text-shadow: #3871c1 0 0 1vw;
  margin: 0;
}

.hero-text {
  font-family: "Sansation";
  font-size: 12vw;
  /* Adjust the size as needed */
  text-shadow: #3871c1 0 0 2vw;
  margin: 0;
  display: flex; /* Use flexbox for alignment */
  align-items: start; /* Align items vertically */
}


.other-text {
  font-family: "Sansation", sans-serif;
  font-size: 7vw;
  /* Adjust the size as needed */
  color: #00a5e7;
  text-shadow: #3871c1 0 0 2vw;
  margin: 0;
}

.hero-text .hero-number {
  width: auto;
  height: 13.5vw; /* Set the height relative to the font size */
}
.hero-number{
  margin-right: -2.6vw;

}

.hero-date,
.hero-location {
  font-family: "Sansation", sans-serif;
  font-size: 2vw;
  margin: 10px 0;
  color: #a6a6a6;
}

.hero-button {
  font-family: "Sansation", sans-serif;
  font-size: 1.25vw;
  color: #ffffff;
  background: #3871c1;
  text-decoration: none;
  padding: 0.5vw 2vw;
  /* Adjusted padding */
  border: none;
  border-radius: 0.2vw;
  box-shadow: 0.1vw 0.15vw 0.5vw black;
  cursor: pointer;
  transition: background-color 0.2s, box-shadow 0.2s, line-height 0.2s;
  /* Added line-height to transition */
  line-height: 2.5;
  /* Adjusted line-height for vertical centering */
}

.hero-button:hover,
.hero-button:focus {
  background: #305ba7;
  box-shadow: 0.2vw 0.3vw 0.6vw black;
  outline: none;
}

.hero-button a {
  color: inherit;
  /* Ensures link color matches button color */
  text-decoration: none;
  display: block;
  /* Makes the link fill the entire button */
}

.hero-button-2 {
  font-family: 'Sansation-Bold', sans-serif;
  font-size: calc(1vw + 1vh);
  /* Increased the font size for better readability */
  color: #305ba7;
  background: #ffffff;
  text-decoration: none;
  opacity: 0.9;
  padding: calc(0.5vw + 0.7vh) calc(1vw + 1.5vh);
  /* Add some padding around the text */
  border: none;
  /* Removes the border */
  /* Slightly rounded corners */
  box-shadow: 0.1vw 0.15vw 0.2vw rgba(0, 0, 0, 0.4);
  /* Subtle shadow to give depth */
  cursor: pointer;
  /* Changes the cursor to indicate it's clickable */
  transition: background-color 0.2s, box-shadow 0.2s;
}

.hero-button-2:hover,
.hero-button:focus {
  background: #ffffff;
  /* Darker shade on hover for feedback */
  box-shadow: 0.2vw 0.3vw 0.6vw rgba(0, 0, 0, 0.4);
  opacity: 1;
  /* Slightly more pronounced shadow on hover */
  outline: none;
  /* Removes the outline on focus, may need to provide alternative focus styles for accessibility */
}

.hero-button-2 a {
  color: inherit;
  /* Ensures link color matches button color */
  text-decoration: none;
  display: block;
  /* Makes the link fill the entire button */
}

.padding-small {
  padding-top: 2vh;
  padding-bottom: 2vh;
}

.padding-med {
  padding-top: 4vh;
  padding-bottom: 4vh;
}

.padding-large {
  padding-top: 6vh;
  padding-bottom: 6vh;
}

.content-container {
  /* previously #ebf8ff */
  background-color: white;
  padding: 40px;
  text-align: center;
}

.content-container-grey {
  background-color: #e8e8e8;
  padding: 40px;
  text-align: center;
}

.title {
  font-family: "Sansation", sans-serif;
  font-size: 4vw;
  font-weight: bold;
  margin-bottom: 24px;
  color: #01a6e8;
  /*text-shadow: #01a6e8 0 0 1vw;*/
}

.item-row {
  display: flex;
  align-items: center;
  gap: 10vw;
  margin-bottom: 24px;
  margin-left: 5vw;
  margin-right: 5vw;
}

.item-row img {
  width: 25vw;
  /* Adjust as necessary */
  height: auto;
  /* Adjust for aspect ratio */
}

.item-row p {
  font-family: "Poppins", sans-serif;
  flex: 1;
  text-align: left;
  font-size: 1.4vw;
  color: black;
}

.right-image {
  flex-direction: row-reverse;
}

.cta-button {
  background-color: #3182ce;
  color: white;
  font-weight: bold;
  padding: 1.5vh 3.5vw;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.center-text {
  background-color: #3871c1;
  padding-top: 20px;
  padding-bottom: 20px;
}

.exclusive-events {
  color: white;
  font-family: "Sansation", sans-serif;
  font-size: 3vw;
}

.spotlight-day {
  font-family: "Sansation", sans-serif;
  text-align: left;
  margin-left: 150px;
  color: #bfc3c6;
}

.spotlight-description {
  font-family: "Poppins", sans-serif;
  text-align: justify;
  margin-left: 150px;
  margin-right: 150px;
  color: white;
}

.prelude-session {
  font-family: "Sansation", sans-serif;
  text-align: left;
  margin-left: 150px;
  color: #bfc3c6;
}

.prelude-description {
  font-family: "Poppins", sans-serif;
  text-align: justify;
  margin-left: 150px;
  margin-right: 150px;
  color: white;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .item-row {
    flex-direction: column;
  }

  .right-image {
    flex-direction: column;
  }
}

.booth {
  font-family: "Sansation", sans-serif;
  text-align: left;
  color: #bfc3c6;
}

.about-button {
  border: none;
  border-radius: 0.2vw;
  box-shadow: 0.1vw 0.15vw 0.5vw black;
  cursor: pointer;
  transition: background-color 0.2s, box-shadow 0.2s;
}

.about-button:hover,
.about-button:focus {
  background: #ffffff;
  box-shadow: 0.2vw 0.3vw 0.6vw black;
  outline: none;
}