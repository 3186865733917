.grid-container {
    width: 100%;
    height: 120vh;
    display: grid;
    grid-template-areas:
    'header main-content'
    'side-bar footer';
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 50vh auto; /* First row slightly shorter */
}

.header {
    grid-area: header;
    background-color: #3871c1;
    padding: 20px;
    color: white;
}

.main-content {
    grid-area: main-content;
    background-color: white;
    padding: 20px;
    color: white;
}

.side-bar {
    grid-area: side-bar;
    background-color: white;
    padding: 20px;
}

.footer {
    grid-area: footer;
    background-color: #3871c1;
    color: white;
}


.Title {
    font-family: "Poppins", sans-serif;
    font-size: 3vw;
    text-align: center;
    text-decoration: none;
    display: flex;
    align-items: center; /* Vertically center */
    justify-content: center; /* Horizontally center */
    height: 100%; /* Ensure the container fills the available height */
}

.contact-email {
    text-decoration: underline;
}

.contact-email:hover{
    text-shadow: 0px -2px 10px #0cf900;
    transition: 0.3 ease-in-out all;
}

.member-table {
    font-family: 'Poppins', sans-serif; /* Use a modern sans-serif font */
    max-width: 600px; /* Max width for better readability */
    margin: 0 auto; /* Center the table in the footer */
    color: #FFFFFF; /* Text color, assuming a dark background */
}

.member-row {
    margin-bottom: 1.5rem; /* Space between member entries */
}

.member-name,
.member-role,
.member-email {
    display: block; /* Each item on its own line */
    margin-bottom: 0.25rem; /* Space between name, role, and email */
}

.member-name {
    font-weight: bold;
    font-size: calc(1vw + 1vh); /* Slightly larger font size for names */
    color: #FFFFFF; /* Name color */
}

.member-role {
    font-size: calc(0.8vw + 0.7vh); /* Smaller font size for roles */
    color: #B0B0B0; /* A lighter shade for the role */
}

.member-email {
    font-size: calc(0.8vw + 0.7vh); /* Consistent font size for email */
    color: #3871c1; /* Brand color for email */
    text-decoration: none; /* Remove underline from emails */
    margin-bottom: 1rem; /* Increase space after the email */
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .member-table {
        max-width: 100%; /* Full width on smaller screens */
        padding: 0 2rem; /* Padding on the sides */
    }
    .member-name {
        font-size: calc(1vw + 0.9vh); /* Adjust font size for smaller screens */
    }
}

/* Hover effect for emails */
.member-email {
    text-decoration: underline; /* Add underline on hover for email links */
    color: #b9b9b9; /* Darken the color on hover for better interaction feedback */
}

/* Add a general transition for smooth hover effects */
.member-table a {
    transition: color 0.3s ease, text-decoration 0.3s ease;
}

/* Accessibility improvements */
.member-email:focus {
    outline: 2px dashed #3871c1; /* Focus style for keyboard navigation */
    outline-offset: 2px;
}
